import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const useLandingPageGalleryData = () => {
        const data = useStaticQuery(graphql`query landingPageGalleryTattoo {
            allContentfulLandingPageGallery {
              nodes {
                myTattoo {
                  title
                  file {
                    url
                  }
                }
                allAround {
                  title
                  file {
                    url
                  }
                }
                myPrints {
                  title
                  file {
                    url
                  }
                }
              }
            }
          }
          `)
    return data.allContentfulLandingPageGallery.nodes[0]
}