import * as React from "react"
import { About } from "../components/about"
import { Galery } from "../components/galery"
import tattoGif from "../video/landingGif.gif"
import tattoVideo from "../video/video.mp4"
import * as styles from "./index.module.css"
import ScrollAnimation from 'react-animate-on-scroll'
import {Layout} from "../components/layout"
import { useLandingPageGalleryData } from "../hooks/landingPage"
import { CONTEN_TYPE_FIELDS, GALLERY_TITLE } from "../constains"

// markup
const IndexPage = () => {
  const data = useLandingPageGalleryData()
  const landing_page_gallery_data = mapLaningpageGalleryData(data);

  // change sidebar position when render landing page
  React.useEffect(() => {
    document.documentElement.style.setProperty("--sidebar_position", "absolute") // change css root property
    return (() => {
      document.documentElement.style.setProperty("--sidebar_position", "relative")
    })
  })
  
  return (
    <Layout>
      <div className={styles.landing_page_layout}>
        <div className={styles.landing_page_layout_items}>
          <video autoPlay loop muted playsInline>
            <source src={tattoVideo} type="video/mp4"/>
          </video>
          {/* ABOUT */}
          <div className={styles.main_body}>
            <ScrollAnimation animateIn='fadeInUp'>
              <About />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp'>
              <Galery data={landing_page_gallery_data}/>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Layout>
  )
}

  
function mapLaningpageGalleryData(data) {

  const joinedTypeToTitle = joinObjects(CONTEN_TYPE_FIELDS, GALLERY_TITLE);

  return (Object.keys(data).filter(item => Object.keys(CONTEN_TYPE_FIELDS).some(el => [CONTEN_TYPE_FIELDS[el] === item])).map(item => {
    return ({url: data[item].file.url, title: joinedTypeToTitle[item], linkTo: `/gallery/${item}`})
  }))
}

function joinObjects(obj1, obj2) {
  return (Object.keys(obj1).reduce((acc, cur) => {
    if(obj2[cur]) {
      acc[obj1[cur]] = obj2[cur]
    }
    return acc
  }, {}))
}

export default IndexPage
