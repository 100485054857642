import React from 'react'
import {Link} from "gatsby"
import * as styles from "./index.module.css"
import myTattooImg from "../../images/gallery_1.jpg"
import allAroundImg from "../../images/gallery_2.jpg"
import myPrintsImg from "../../images/gallery_3.jpg"
import {CONTEN_TYPE_FIELDS} from "../../constains"

const langingPageGalleryImg = [
    {
        name: "My Tattoo",
        file: myTattooImg,
        linkTo: `/gallery/${CONTEN_TYPE_FIELDS.MY_TATTOO}`
    },
    {
        name: "All Around",
        file: allAroundImg,
        linkTo: `/gallery/${CONTEN_TYPE_FIELDS.ALL_AROUND}`
    },
    {
        name: "My Prints",
        file: myPrintsImg,
        linkTo: `/gallery/${CONTEN_TYPE_FIELDS.MY_PRINTS}`
    }
]

export const Galery = ({data}) => {
    return (
        <div id="gallery" className={styles.galery_container}>
            <h1 style={{color: "white"}}>Gallery</h1>
            <div className={styles.gallery_items}>
                {data.map(item => <GalleryImg galleryName={item.title} galleryImage={item.url} linkTo={item.linkTo}/>)}
            </div>
        </div>
    )
}


function GalleryImg ({galleryName, galleryImage, linkTo}) {
    const imgRef = React.useRef(null)

    // use react to handle the element style
    const handleOnMouseEnter = () => {
        imgRef.current.style.transform = 'scale(1.2)'
        imgRef.current.style.transition = 'transform 1s'
    }

    const handleOnMouseLeave = () => {
        imgRef.current.style.transform = 'scale(1)'
    }

    return (
        <Link to={(linkTo || "#")}>
            <div className={styles.gallery_picture_container} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                <img ref={imgRef} className={styles.gallery_picture} src={galleryImage} alt="tsampos_tattoo_artist_gallery_image"/>
                <div className={styles.gallery_name_container}>
                    <h3>{galleryName}</h3>
                </div>
            </div>
        </Link>
    )
}